import React, { Component } from 'react';
import { Config } from '../../../common/config';
import { formatDate } from '../../../common/date'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import '../../../css/qna/qna_same_day_style.css';

class QnaSameDayList extends Component {

    constructor(props) {
        super(props);

        this.orderNumber = props.orderNumber;

        this.state = {
            isLoading: false,
            inquiryItems: [],
            openAlert: false,
            alertTitle: '',
            alertMessage: '',
        }
    }

    componentDidMount() {
        this.fetchInquiryList();
    }

    async fetchInquiryList() {
        this.setState({ isLoading: true });
        const url = Config.api.cs_url + "/inquiry/list/" + this.orderNumber;

        try {
            const response = await fetch(url, {
                method: "GET",
                crossDomain: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            })
            if (!response.ok) { throw response }
            const body = await response.json()

            this.setState({
                inquiryItems: body,
                isLoading: false,
            });
        } catch (error) {

            this.setState({ isLoading: false });
            let body = {
                'message': '반복적인 요청으로 데이터를 불러올 수 없습니다.\n잠시 후 다시 시도해주세요'
            }
            if (error.json) {
                body = error.json()
            } else {
                console.log(error)
            }


            if (error.status === 404) {
                body['message'] = '주문정보가 없습니다.'
            } else if (error.status === 403) {
                body['message'] = '반복적인 요청으로 데이터를 불러올 수 없습니다.<br/>잠시 후 다시 시도해주세요'
            }

            this.openAlertDialog('알림', 'message' in body ? body.message : '오류가 발생했습니다.');
        }
    }

    handleAlertClose = () => {
        this.setState({
            openAlert: false,
        })
    }

    openAlertDialog = (title, message) => {
        this.setState({
            openAlert: true,
            alertTitle: title,
            alertMessage: message,
        });
    }

    onClickedImage = (image) => {
        this.modalDisplay("block");
        const modal_img = document.querySelector(".modal_content");
        modal_img.src = image;
    }

    modalDisplay = (text) => {
        const modal = document.querySelector(".modal");
        modal.style.display = text;
    }

    render() {
        return (
            <div className='main_qna_list'>
                <div className='tab_container'>
                    {
                        this.state.isLoading ?
                            <div className='empty_qna'>
                                <CircularProgress className="spinner" size='50px' />
                            </div> :
                            this.state.inquiryItems.length > 0 ? this.state.inquiryItems.map((item, index) => {
                                const createdDate = new Date(item.createdAt * 1000);
                                const answeredDate = new Date(item.answeredAt * 1000);

                                return (
                                    <Accordion key={index} elevation={3}>
                                        <AccordionSummary expandIcon={<ExpandMore />}>
                                            <div className='summary_container'>
                                                <div className='is_answered'>{item.answeredAt > 0 ? '[답변완료]' : ''}</div>
                                                <div className='question_summary'>{item.question}</div>
                                                <div className='question_date'>{formatDate(createdDate, "yyyy/MM/dd")}</div>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className='detail_container'>
                                                <div className='question_detail'>{item.question.split('\n').map((line, index) => {
                                                    return (<span key={'q-' + index}>{line}<br /></span>)
                                                })}</div>
                                                <p></p>
                                                {item.answeredAt > 0 ?
                                                    <div>
                                                        <div className='answer_detail'>{item.answer.split('\n').map((line, index) => {
                                                            return (<span key={'a-' + index}>{line}<br /></span>)
                                                        })}</div>
                                                        <div className='answer_detail'>{formatDate(answeredDate, "yyyy/MM/dd")}</div>
                                                    </div> :
                                                    <div />
                                                }
                                                <div className='space_v20' />
                                                {
                                                    item.images.length > 0
                                                        ?
                                                        <img
                                                            class="center-cropped"
                                                            src={item.images[0]}
                                                            aria-hidden alt={"uploaded image"}
                                                            onClick={() => this.onClickedImage(item.images[0])}
                                                        />

                                                        : <div></div>
                                                }
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            }) : <div className='empty_qna'>문의내역이 없습니다.</div>
                    }
                    <Dialog
                        open={this.state.openAlert}
                        onClose={this.handleAlertClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{this.state.alertTitle == null ? '' : this.state.alertTitle}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {this.state.alertMessage.split('\n').map((line) => <div>{line}</div>)}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleAlertClose}>
                                확인
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <div class="modal" onClick={() => this.modalDisplay("none")}>
                        <span class="close" onClick={() => this.modalDisplay("none")}>&times;</span>
                        <img class="modal_content" id="full_image" aria-hidden alt={"full image"} />
                    </div>
                </div>
            </div>
        )
    }
}

export default QnaSameDayList;