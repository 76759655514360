export function formatDate(date, format) {
    var weekKorName = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];
    var weekKorShortName = ["일", "월", "화", "수", "목", "금", "토"];
    var weekEngName = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var weekEngShortName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    function zf(value, len) {
        let valueString = ''
        if (typeof value == 'number') {
            valueString = value.toString();
        } else if (typeof value == 'string') {
            valueString = value;
        }
        var padding = '', i = 0;
        while (i++ < (len - valueString.length)) {
            padding += '0';
        }
        return padding + valueString;
    }

    return format.replace(/(yyyy|yy|MM|M|dd|d|KS|KL|ES|EL|HH|hh|mm|ss|a\/p)/gi, function ($1) {
        switch ($1) {
            case "yyyy": return date.getFullYear(); // 년 (4자리)
            //case "yy": return (date.getFullYear() % 1000).zf(2); // 년 (2자리)
            case "yy": return zf(date.getFullYear() % 100, 2); // 년 (2자리)
            //case "MM": return (date.getMonth() + 1).zf(2); // 월 (2자리)
            case "MM": return zf(date.getMonth() + 1, 2); // 월 (2자리)
            case "M": return date.getMonth() + 1; // 월
            //case "dd": return date.getDate().zf(2); // 일 (2자리)
            case "dd": return zf(date.getDate(), 2); // 일 (2자리)
            case "d": return date.getDate(); // 일
            case "KS": return weekKorShortName[date.getDay()]; // 요일 (짧은 한글)
            case "KL": return weekKorName[date.getDay()]; // 요일 (긴 한글)
            case "ES": return weekEngShortName[date.getDay()]; // 요일 (짧은 영어)
            case "EL": return weekEngName[date.getDay()]; // 요일 (긴 영어)
            //case "HH": return date.getHours().zf(2); // 시간 (24시간 기준, 2자리)
            case "HH": return zf(date.getHours(), 2); // 시간 (24시간 기준, 2자리)
            case "hh":
                var h = date.getHours() % 12;
                //return ((h) ? h : 12).zf(2); // 시간 (12시간 기준, 2자리)
                return zf((h) ? h : 12, 2); // 시간 (12시간 기준, 2자리)
            //case "mm": return date.getMinutes().zf(2); // 분 (2자리)
            case "mm": return zf(date.getMinutes(), 2); // 분 (2자리)
            //case "ss": return date.getSeconds().zf(2); // 초 (2자리)
            case "ss": return zf(date.getSeconds(), 2); // 초 (2자리)
            case "a/p": return date.getHours() < 12 ? "오전" : "오후"; // 오전/오후 구분
            default: return $1;
        }
    });
}

function pad(a, b) {
    return (1e15 + a + '').slice(-b);
}

export function timeStringToMinutes(timestring) {
    let _split = timestring.split(":")
    return parseInt(_split[0] * 60) + parseInt(_split[1])
}
export function minutesToTimeString(_minutes) {
    let minutes = parseInt(_minutes)
    return pad(parseInt(minutes / 60), 2) + ":" + pad(minutes % 60, 2)
}
