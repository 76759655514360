class ConfigManager {
    constructor() {
        this.api = {
            cs_url: "https://cs-api-dev.0br1.io",
            track_url: "https://track-dev.pingpongpingpong.com",
        }
    }
}

let Config = new ConfigManager()
export { Config }